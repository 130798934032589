export const vocabulary = [
    "why",
    "changes",
    "weren't",
    "saved",
    "after",
    "each",
    "and",
    "every",
    "change",
    "the",
    "system",
    "you",
    "have",
    "save",
    "your",
    "clicking",
    "apply",
    "how",
    "logout",
    "need",
    "find",
    "agent",
    "map",
    "then",
    "click",
    "three",
    "dots",
    "button",
    "will",
    "appear",
    "where",
    "can",
    "out",
    "more",
    "information",
    "about",
    "settings",
    "users",
    "desired",
    "user",
    "see",
    "them",
    "want",
    "display",
    "our",
    "types",
    "displaying",
    "card",
    "list",
    "choose",
    "icon",
    "upper",
    "left",
    "corner",
    "what",
    "sip",
    "password",
    "secret",
    "for",
    "connection",
    "softphone",
    "ip-phone",
    "adding",
    "domain",
    "server",
    "address",
    "number",
    "register",
    "does",
    "days",
    "mean",
    "last",
    "time",
    "was",
    "current",
    "status",
    "application",
    "voip",
    "panel",
    "pause",
    "ready",
    "action",
    "means",
    "that",
    "cannot",
    "receive",
    "make",
    "eternal",
    "calls",
    "but",
    "still",
    "internal",
    "add",
    "new",
    "check",
    "manual",
    "created",
    "agents",
    "are",
    "view",
    "edit",
    "permissions",
    "permission",
    "gives",
    "opportunity",
    "some",
    "permitted",
    "tabs",
    "access",
    "allowed",
    "any",
    "field",
    "departments",
    "department",
    "way",
    "group",
    "v-numbers",
    "also",
    "managers",
    "manage",
    "these",
    "they",
    "such",
    "other",
    "different",
    "same",
    "yes",
    "present",
    "delete",
    "queue",
    "specific",
    "configure",
    "incoming",
    "order",
    "multiple",
    "note",
    "limited",
    "parallel",
    "per",
    "one",
    "call",
    "net",
    "person",
    "ring",
    "use",
    "directed",
    "this",
    "terminated",
    "strategy",
    "set",
    "rules",
    "all",
    "least",
    "recent",
    "who",
    "did",
    "not",
    "longest",
    "fewest",
    "received",
    "random",
    "randomly",
    "distribute",
    "memory",
    "calling",
    "circle",
    "answered",
    "linear",
    "starting",
    "from",
    "first",
    "listed",
    "timeout",
    "waiting",
    "before",
    "recall",
    "again",
    "allow",
    "skip",
    "busy",
    "continue",
    "anyway",
    "v-number",
    "cli&did",
    "used",
    "contacting",
    "description",
    "tet",
    "row",
    "describe",
    "purposes",
    "usage",
    "numbers",
    "route",
    "needed",
    "setting",
    "certain",
    "sequence",
    "according",
    "wishes",
    "routings",
    "only",
    "routing",
    "without",
    "template",
    "work",
    "added",
    "allows",
    "making",
    "choosing",
    "direction",
    "has",
    "priority",
    "similar",
    "routes",
    "restricted",
    "manager",
    "outbound",
    "difference",
    "between",
    "preferred",
    "directions",
    "assigned",
    "selected",
    "considering",
    "best",
    "metrics",
    "previous",
    "would",
    "chosen",
    "even",
    "direction",
    "additional",
    "charge",
    "applied",
    "when",
    "keep",
    "special",
    "сalls",
    "history",
    "report",
    "search",
    "necessary",
    "using",
    "filters",
    "cdrs",
    "filtered",
    "reports",
    "localdst",
    "another",
    "billing",
    "financial",
    "statistics",
    "statement",
    "increase",
    "deposit",
    "ask",
    "form",
    "invoice",
    "able",
    "invoices",
    "payments",
    "main",
    "page",
    "shows",
    "actions",
    "connect",
    "software",
    "phone",
    "know",
    "basic",
    "parameters",
    "dns",
    "telephony-system",
    "dashboard",
    "contains",
    "may",
    "displayed",
    "cards",
    "their",
    "real",
    "live",
    "statuses",
    "there",
    "eample",
    "online",
    "already",
    "logged",
    "offline",
    "yet",
    "user's",
    "talking",
    "now",
    "paused",
    "unavailable",
    "registered",
    "unreachable",
    "turn",
    "mode",
    "move",
    "wherever",
    "empty",
    "space",
    "customised",
    "return",
    "enter",
    "mind",
    "must",
    "role",
    "default",
    "called",
    "insettings",
    "look",
    "create",
    "with",
    "given",
    "onsettings",
    "block",
    "account",
    "forbid",
    "receiving",
    "his",
    "line",
    "limit",
    "pages",
    "editing",
    "login",
    "into",
    "don't",
    "forget",
    "discard",
    "which",
    "turns",
    "name",
    "available",
    "till",
    "back",
    "managing",
    "give",
    "overview",
    "you've",
    "batch",
    "than",
    "simultaneously",
    "minimum",
    "press",
    "close",
    "update",
    "company",
    "position",
    "departments-",
    "rewrite",
    "remove",
    "down",
    "below",
    "remember",
    "tab",
    "convenience",
    "config",
    "deletion",
    "removed",
    "queues",
    "contain",
    "digits",
    "organise",
    "requests",
    "spread",
    "specified",
    "retry",
    "agent`s",
    "place",
    "matches",
    "put",
    "correct",
    "drag",
    "required",
    "once",
    "unused",
    "plus",
    "menu",
    "bottom",
    "dial",
    "type",
    "near",
    "trash",
    "rule",
    "were",
    "priorities",
    "following",
    "function",
    "get",
    "cost",
    "listen",
    "download",
    "don`t",
    "recordings",
    "missing",
    "sure",
    "cdr",
    "fast",
    "forward",
    "сlick",
    "rewind",
    "pull",
    "slider",
    "volume",
    "adjusted",
    "due",
    "results",
    "contact",
    "payment",
    "level",
    "wire",
    "transfer",
    "got",
    "receipt",
    "upload",
    "processing",
    "transaction",
    "international",
    "please",
    "country",
    "code",
    "concrete",
    "echo",
    "test",
    "live-calls",
    "spy",
    "being",
    "nobody",
    "hear",
    "whisper",
    "talk",
    "merge",
    "ability",
    "everyone",
    "attend",
    "pick",
    "come",
    "blind",
    "direct",
    "answer",
    "gone"
]
