<template>
  <div class="call-menu">
    <ul class="call-menu-list">

      <li class="call-menu-item" >
        <span class="call-menu-btn" :class="{active: isMuted(), disabled: getSetSessionShown && getSetSessionShown.status === 2}" @click="toggleMute()">
          <svg width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.283 15.7176V5.35977C18.283 2.30174 16.2703 0 13.2843 0C10.3314 0 8.2857 2.25242 8.2857 5.22824V5.73791L18.283 15.7176ZM27.3069 29.9884C27.7029 30.383 28.3298 30.383 28.7092 29.9884C29.1051 29.6267 29.0886 28.9855 28.7092 28.6073L1.68684 1.69342C1.3239 1.31528 0.664011 1.31528 0.284576 1.69342C-0.0948587 2.07157 -0.0948587 2.71277 0.284576 3.09091L27.3069 29.9884ZM5.69565 32.0764C5.16774 32.0764 4.72232 32.5368 4.72232 33.0464C4.72232 33.5561 5.16774 34 5.69565 34H20.873C21.3845 34 21.8299 33.5561 21.8299 33.0464C21.8299 32.5368 21.3845 32.0764 20.873 32.0764H14.2412V27.7524C16.6498 27.588 18.7119 26.7824 20.2956 25.5L18.9264 24.1354C17.4746 25.3027 15.5445 25.9768 13.2843 25.9768C7.87328 25.9768 4.19441 22.2118 4.19441 17.0822V13.5474C4.19441 12.9555 3.81497 12.5774 3.25407 12.5774C2.69316 12.5774 2.31373 12.9555 2.31373 13.5474V17.0822C2.31373 23.001 6.27305 27.3414 12.311 27.7524V32.0764H5.69565ZM24.2385 13.5474C24.2385 12.9555 23.859 12.5774 23.2981 12.5774C22.7372 12.5774 22.3578 12.9555 22.3578 13.5474V17.0822C22.3578 17.9207 22.2423 18.7263 22.0443 19.4662L23.5951 21.0116C24.0075 19.8114 24.2385 18.4961 24.2385 17.0822V13.5474ZM16.0229 21.2418L8.2857 13.5145V16.8356C8.2857 19.8936 10.2984 22.1789 13.2843 22.1789C14.3732 22.1789 15.3135 21.8337 16.0229 21.2418Z" fill="white"/>
          </svg>
        </span>
        <span class="text">Mute</span>
      </li>
      
      <li class="call-menu-item">
        <span class="call-menu-btn" :class="{disabled: getSetSessionShown && getSetSessionShown.status === 2}" @click="toggleKeyboard()">
          <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.22096 0H0.991501C0.446176 0 0 0.438718 0 0.97493V9.06684C0 9.60306 0.446176 10.0418 0.991501 10.0418H9.22096C9.76629 10.0418 10.2125 9.60306 10.2125 9.06684V0.97493C10.2125 0.438718 9.76629 0 9.22096 0ZM8.32861 8.18941H1.983V1.94986H8.32861V8.18941Z" fill="white"/>
            <path d="M21.6147 0H13.3853C12.8399 0 12.3938 0.438718 12.3938 0.97493V9.06684C12.3938 9.60306 12.8399 10.0418 13.3853 10.0418H21.6147C22.1601 10.0418 22.6063 9.60306 22.6063 9.06684V0.97493C22.6063 0.438718 22.1601 0 21.6147 0ZM20.7224 8.18941H14.3768V1.94986H20.7224V8.18941Z" fill="white"/>
            <path d="M9.22096 12.2841H0.991501C0.446176 12.2841 0 12.7229 0 13.2591V21.351C0 21.8872 0.446176 22.3259 0.991501 22.3259H9.22096C9.76629 22.3259 10.2125 21.8872 10.2125 21.351V13.2591C10.2125 12.7229 9.76629 12.2841 9.22096 12.2841ZM8.32861 20.376H1.983V14.234H8.32861V20.376Z" fill="white"/>
            <path d="M21.6147 12.2841H13.3853C12.8399 12.2841 12.3938 12.7229 12.3938 13.2591V21.351C12.3938 21.8872 12.8399 22.3259 13.3853 22.3259H21.6147C22.1601 22.3259 22.6063 21.8872 22.6063 21.351V13.2591C22.6063 12.7229 22.1601 12.2841 21.6147 12.2841ZM20.7224 20.376H14.3768V14.234H20.7224V20.376Z" fill="white"/>
            <path d="M34.0085 0H25.779C25.2337 0 24.7875 0.438718 24.7875 0.97493V9.06684C24.7875 9.60306 25.2337 10.0418 25.779 10.0418H34.0085C34.5538 10.0418 35 9.60306 35 9.06684V0.97493C35 0.438718 34.5538 0 34.0085 0ZM33.1161 8.18941H26.7705V1.94986H33.1161V8.18941Z" fill="white"/>
            <path d="M34.0085 12.2841H25.779C25.2337 12.2841 24.7875 12.7229 24.7875 13.2591V21.351C24.7875 21.8872 25.2337 22.3259 25.779 22.3259H34.0085C34.5538 22.3259 35 21.8872 35 21.351V13.2591C35 12.7229 34.5538 12.2841 34.0085 12.2841ZM33.1161 20.376H26.7705V14.234H33.1161V20.376Z" fill="white"/>
            <path d="M9.22096 24.9582H0.991501C0.446176 24.9582 0 25.3969 0 25.9331V34.0251C0 34.5613 0.446176 35 0.991501 35H9.22096C9.76629 35 10.2125 34.5613 10.2125 34.0251V25.9331C10.2125 25.3969 9.76629 24.9582 9.22096 24.9582ZM8.32861 33.1476H1.983V26.9081H8.32861V33.1476Z" fill="white"/>
            <path d="M21.6147 24.9582H13.3853C12.8399 24.9582 12.3938 25.3969 12.3938 25.9331V34.0251C12.3938 34.5613 12.8399 35 13.3853 35H21.6147C22.1601 35 22.6063 34.5613 22.6063 34.0251V25.9331C22.6063 25.3969 22.1601 24.9582 21.6147 24.9582ZM20.7224 33.1476H14.3768V26.9081H20.7224V33.1476Z" fill="white"/>
            <path d="M34.0085 24.9582H25.779C25.2337 24.9582 24.7875 25.3969 24.7875 25.9331V34.0251C24.7875 34.5613 25.2337 35 25.779 35H34.0085C34.5538 35 35 34.5613 35 34.0251V25.9331C35 25.3969 34.5538 24.9582 34.0085 24.9582ZM33.1161 33.1476H26.7705V26.9081H33.1161V33.1476Z" fill="white"/>
          </svg>
        </span>
        <span class="text">Dialpad</span>
      </li>

      <li class="call-menu-item" >
        <span class="call-menu-btn" :class="{disabled: getSetSessionShown && getSetSessionShown.status === 2}">
          <svg width="36" height="34" viewBox="0 0 36 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.137 0.678121C24.6113 0.448358 23.999 0.688438 23.7693 1.21402C23.5396 1.73961 23.7795 2.35203 24.3052 2.58173C30.009 5.07421 33.6947 10.7059 33.695 16.9294C33.695 23.1532 30.0091 28.7851 24.3047 31.2775C23.779 31.5072 23.5391 32.1195 23.7688 32.6452C23.9393 33.0356 24.3208 33.2683 24.7212 33.2683C24.86 33.2683 25.0011 33.2404 25.1365 33.1811C31.5975 30.3582 35.7724 23.979 35.7724 16.9293C35.7721 9.88017 31.5975 3.50119 25.137 0.678121Z" fill="white"/>
            <path d="M25.4132 8.69399C24.9858 8.3114 24.3291 8.34769 23.9465 8.77508C23.5639 9.20254 23.6002 9.85914 24.0276 10.2418C25.9285 11.9436 27.0187 14.3815 27.0187 16.9307C27.0187 19.4797 25.9285 21.9178 24.0276 23.6195C23.6002 24.0021 23.5638 24.6588 23.9465 25.0862C24.1516 25.3154 24.4356 25.4321 24.7207 25.4321C24.9674 25.4321 25.2149 25.3448 25.4132 25.1673C27.7538 23.072 29.0962 20.0698 29.0962 16.9306C29.0962 13.7914 27.7537 10.7892 25.4132 8.69399Z" fill="white"/>
            <path d="M18.5151 3.20933C18.166 3.0324 17.747 3.06682 17.4314 3.29824L9.36134 9.21617H1.35707C0.783423 9.21617 0.318359 9.68124 0.318359 10.2549V23.6058C0.318359 24.1794 0.783423 24.6445 1.35707 24.6445H9.36128L17.4313 30.5626C17.613 30.6958 17.8287 30.7637 18.0457 30.7637C18.2059 30.7637 18.3669 30.7266 18.5151 30.6515C18.8643 30.4746 19.0843 30.1163 19.0843 29.725V4.13572C19.0843 3.74433 18.8643 3.38612 18.5151 3.20933ZM17.0069 27.675L10.3156 22.7681C10.1374 22.6374 9.92225 22.567 9.70128 22.567H2.39577V11.2936H9.70128C9.92218 11.2936 10.1374 11.2231 10.3155 11.0926L17.0069 6.18564V27.675Z" fill="white"/>
          </svg>
        </span>
        <span class="text">Audio</span>
      </li>

      <li class="call-menu-item">
        <span class="call-menu-btn disabled">
          <svg width="47" height="49" viewBox="0 0 47 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.3598 7.24022C32.3185 7.2976 32.2722 7.35125 32.2216 7.40059L23.71 15.9122L23.9819 15.8129C24.2492 15.7174 24.5311 15.6692 24.8149 15.6705L29.5887 15.6705C30.2031 15.6562 30.7387 16.0862 30.8576 16.6891C30.9606 17.3555 30.504 17.9791 29.8377 18.0821C29.7838 18.0905 29.7295 18.0952 29.675 18.0962L21.0425 18.0962C20.3683 18.0966 19.8214 17.5503 19.821 16.8761C19.821 16.8757 19.821 16.8752 19.821 16.8747L19.821 8.24228C19.822 7.56806 20.3693 7.02225 21.0436 7.02316C21.0966 7.02324 21.1496 7.02678 21.2022 7.03374C21.8052 7.1527 22.2351 7.68827 22.2209 8.30271L22.2295 13.0765C22.2296 13.3299 22.1903 13.5818 22.113 13.8232L21.9705 14.1987L30.4476 5.72161C30.8722 5.26425 31.5671 5.1854 32.0834 5.53601C32.6304 5.9303 32.7541 6.69327 32.3598 7.24022Z" fill="#515960"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M31.187 44.4438C16.2243 44.4438 4.05 32.2695 4.05 17.3067C4.05 14.4049 6.41115 12.0438 9.315 12.0438C9.53572 12.0438 9.75645 12.0559 9.9792 12.0863L12.1277 21.4418C11.7713 21.6281 11.3825 21.8306 11.0241 22.0189C9.26033 22.9403 7.9866 23.6085 8.63865 25.2326C11.018 32.0103 16.4835 37.4757 23.1802 39.8247C24.8953 40.5112 25.5514 39.2314 26.4728 37.4697C26.6632 37.1092 26.8657 36.7224 27.052 36.366L36.4075 38.5146C36.4358 38.7312 36.45 38.954 36.45 39.1788C36.45 42.0826 34.0888 44.4438 31.187 44.4438ZM40.3704 37.6357C40.2894 37.1295 40.1618 36.6333 39.9958 36.1575C39.769 35.4993 39.2161 35.0032 38.5378 34.8473L26.4607 32.0751C25.7944 31.9272 25.0978 32.1156 24.6058 32.5874C24.3162 32.8648 24.3101 32.8709 22.9939 35.3859C18.6604 33.4116 15.1186 29.8759 13.1119 25.4979C15.6208 24.1836 15.6289 24.1776 15.9064 23.888C16.3782 23.3959 16.5706 22.6973 16.4187 22.0331L13.6465 9.956C13.4885 9.2756 12.9944 8.7248 12.3363 8.498C11.8564 8.32992 11.3582 8.20437 10.8641 8.1254C10.3599 8.03832 9.84352 7.99377 9.315 7.99377C4.1796 7.99377 0 12.1713 0 17.3067C0 34.503 13.9887 48.4938 31.187 48.4938C36.3224 48.4938 40.5 44.3142 40.5 39.1788C40.5 38.6543 40.4554 38.1379 40.3704 37.6357Z" fill="#515960"/>
            <rect x="37.5" y="13.9957" width="3" height="15" rx="1.5" fill="#515960"/>
            <rect x="31.501" y="19.9978" width="15" height="3" rx="1.5" fill="#515960"/>
          </svg>
        </span>
        <span class="text">Add</span>
      </li>

      <li class="call-menu-item" >
        <span class="call-menu-btn" :class="{active: isHold(), disabled: getSetSessionShown && getSetSessionShown.status === 2}" @click="setHold()">
          <svg width="41" height="45" viewBox="0 0 41 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M31.4946 40.0654C16.5319 40.0654 4.35762 27.8911 4.35762 12.9283C4.35762 10.0265 6.71877 7.66536 9.62262 7.66536C9.84334 7.66536 10.0641 7.67751 10.2868 7.70788L12.4353 17.0634C12.0789 17.2497 11.6901 17.4522 11.3317 17.6405C9.56794 18.5619 8.29422 19.2301 8.94627 20.8542C11.3256 27.6319 16.7911 33.0973 23.4878 35.4463C25.203 36.1328 25.8591 34.853 26.7804 33.0913C26.9708 32.7308 27.1733 32.344 27.3596 31.9876L36.7151 34.1362C36.7434 34.3528 36.7576 34.5756 36.7576 34.8004C36.7576 37.7042 34.3965 40.0654 31.4946 40.0654ZM40.678 33.2573C40.597 32.7511 40.4694 32.2549 40.3034 31.7791C40.0766 31.1209 39.5238 30.6248 38.8454 30.4689L26.7683 27.6967C26.1021 27.5488 25.4055 27.7372 24.9134 28.209C24.6238 28.4864 24.6177 28.4925 23.3015 31.0075C18.968 29.0332 15.4263 25.4975 13.4195 21.1195C15.9285 19.8052 15.9366 19.7992 16.214 19.5096C16.6858 19.0175 16.8782 18.3189 16.7263 17.6547L13.9541 5.57758C13.7961 4.89718 13.302 4.34638 12.6439 4.11958C12.164 3.95151 11.6658 3.82596 11.1717 3.74698C10.6675 3.65991 10.1511 3.61536 9.62262 3.61536C4.48722 3.61536 0.307617 7.79293 0.307617 12.9283C0.307617 30.1246 14.2963 44.1154 31.4946 44.1154C36.63 44.1154 40.8076 39.9358 40.8076 34.8004C40.8076 34.2759 40.7631 33.7595 40.678 33.2573Z" fill="white"/>
            <rect x="20.0625" width="2.89286" height="11.5714" rx="1.44643" fill="white"/>
            <rect x="24.8828" width="2.89286" height="11.5714" rx="1.44643" fill="white"/>
          </svg>
        </span>
        <span class="text">Hold</span>
      </li>

      <li class="call-menu-item">
        <span class="call-menu-btn disabled">
          <svg width="42" height="44" viewBox="0 0 42 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.8032 19.0036L36.576 16.2004C36.1298 15.7492 32.4117 12.2895 28.2759 15.6584C29.1599 17.5799 28.3021 19.927 26.3693 20.8883C25.4386 21.3487 24.3782 21.4235 23.4203 21.0962C22.4625 20.769 21.6854 20.0663 21.2594 19.1424C20.3935 17.2205 21.2332 14.8738 23.166 13.9124C24.2108 13.3615 25.4549 13.3671 26.4908 13.8537C28.2133 12.3648 30.4138 11.5839 32.6358 11.6729C34.8579 11.7618 36.9308 12.7139 38.4246 14.3316L41.1974 11.5284L41.0705 18.8753L33.8032 19.0036ZM25.6433 18.2296C25.8884 17.9817 26.0295 17.649 26.0355 17.3046C26.0414 16.9601 25.9118 16.6322 25.675 16.3928C25.4383 16.1535 25.1139 16.0225 24.7732 16.0285C24.4325 16.0345 24.1033 16.1771 23.8582 16.4249C23.6131 16.6727 23.472 17.0055 23.4661 17.3499C23.4601 17.6944 23.5898 18.0223 23.8265 18.2616C24.0632 18.5009 24.3876 18.632 24.7283 18.626C25.069 18.62 25.3982 18.4774 25.6433 18.2296ZM31.1415 9.06191C32.2692 8.96855 33.3941 9.0405 34.4887 9.28742L38.5832 5.14807L41.2609 7.85502L41.3878 0.508179L34.1205 0.636473L36.7981 3.34343L31.1415 9.06191Z" fill="#515960"/>
            <path d="M23.1635 8.15146L25.9644 5.37622C26.4064 5.83184 29.7926 9.62475 26.4184 13.7721C24.526 12.8594 22.1953 13.7033 21.225 15.6476C20.7602 16.5838 20.6758 17.6551 20.9901 18.6267C21.3044 19.5982 21.9918 20.3907 22.9017 20.8305C24.7946 21.7249 27.1248 20.8993 28.0952 18.955C28.6506 17.9043 28.6574 16.6466 28.1862 15.5946C29.6764 13.8681 30.4709 11.6513 30.4049 9.40424C30.3389 7.15712 29.4174 5.05215 27.8317 3.52607L30.6327 0.750831L23.3625 0.806246L23.1635 8.15146ZM23.8484 16.4079C24.096 16.1625 24.4266 16.0233 24.7675 16.0207C25.1083 16.0181 25.4315 16.1524 25.6659 16.394C25.9004 16.6357 26.0268 16.9649 26.0175 17.3093C26.0081 17.6537 25.8638 17.985 25.6162 18.2303C25.3686 18.4757 25.038 18.615 24.6971 18.6176C24.3562 18.6202 24.0331 18.4859 23.7986 18.2442C23.5642 18.0025 23.4378 17.6733 23.4471 17.3289C23.4564 16.9845 23.6008 16.6532 23.8484 16.4079ZM32.9733 10.9407C33.0769 9.80166 33.0168 8.66385 32.7834 7.5549L36.9194 3.4568L34.2678 0.723125L41.5379 0.667711L41.3389 8.01292L38.6872 5.27925L32.9733 10.9407Z" fill="#515960"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M31.187 39.2193C16.2243 39.2193 4.05 27.045 4.05 12.0823C4.05 9.18044 6.41115 6.81929 9.315 6.81929C9.53572 6.81929 9.75645 6.83144 9.9792 6.86181L12.1277 16.2173C11.7713 16.4036 11.3825 16.6061 11.0241 16.7944C9.26032 17.7158 7.9866 18.3841 8.63865 20.0081C11.018 26.7858 16.4835 32.2513 23.1802 34.6003C24.8953 35.2867 25.5514 34.0069 26.4728 32.2452C26.6632 31.8847 26.8657 31.498 27.052 31.1416L36.4075 33.2901C36.4358 33.5068 36.45 33.7295 36.45 33.9543C36.45 36.8581 34.0888 39.2193 31.187 39.2193ZM40.3704 32.4112C40.2894 31.905 40.1618 31.4089 39.9958 30.933C39.769 30.2749 39.2161 29.7787 38.5378 29.6228L26.4607 26.8506C25.7944 26.7028 25.0978 26.8911 24.6058 27.3629C24.3162 27.6403 24.3101 27.6464 22.9939 30.1615C18.6604 28.1871 15.1186 24.6514 13.1119 20.2734C15.6208 18.9592 15.6289 18.9531 15.9064 18.6635C16.3782 18.1714 16.5706 17.4728 16.4187 16.8086L13.6465 4.73151C13.4885 4.05111 12.9944 3.50031 12.3363 3.27351C11.8564 3.10544 11.3582 2.97989 10.8641 2.90091C10.3599 2.81384 9.84352 2.76929 9.315 2.76929C4.1796 2.76929 0 6.94686 0 12.0823C0 29.2786 13.9887 43.2693 31.187 43.2693C36.3224 43.2693 40.5 39.0897 40.5 33.9543C40.5 33.4298 40.4554 32.9134 40.3704 32.4112Z" fill="#515960"/>
          </svg>
        </span>
        <span class="text">Transfer</span>
      </li>

    </ul>

    <span class="phone-down-btn">
      <img src="@/assets/img/icons/Decline.svg" @click="decline()" />
    </span>
  </div>

</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CallMenu',
  props: ['toggleKeyboard'],
  computed: {
    ...mapGetters(['activeSession', 'getSetSessionShown'])
  },
  methods: {
    isMuted() {
      return this.getSetSessionShown?.isMuted().audio
    },
    isHold() {
      return this.getSetSessionShown?.isOnHold().local
    },
    decline() {
      this.$store.dispatch('terminateSession', {
        terminateObj: {
          reason_phrase: this.getSetSessionShown.causes.BUSY, status_code: 486
        },
        session: this.getSetSessionShown})
    },
    setHold() {
      if(this.activeSession !== this.getSetSessionShown) {
        return this.$store.commit('changeSession', this.getSetSessionShown)
      }
      this.toggleHold()
    },
    toggleMute() {
      if(this.isMuted()) {
        return this.$store.dispatch('unmuteSession', {session: this.activeSession})
      }
      return this.$store.dispatch('muteSession', {session: this.activeSession})
    },
    toggleHold() {
      if(this.isHold()) {
        return this.$store.dispatch('unHoldSession', {session: this.activeSession})
      }
      return this.$store.dispatch('holdSession', {session: this.activeSession})
    }
  }
}
</script>
<style lang="scss" scoped>
  .call-menu {
    margin-top: 40px;
    .call-menu-list {
      padding: 0 45px 27px 45px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .call-menu-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 22px;
        margin-right: 28px;
        

        &:nth-child(3n) {
          margin-right: 0;
        }

        .call-menu-btn {
          width: 75px;
          height: 75px;
          background: var(--phone-call-menu-btns-bg);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          margin-bottom: 10px;

          svg {
            path, rect {
              fill: #FFFFFF;
            }
          }

          &.active {
            background: var(--phone-call-menu-btn-active-bg);
          }

          &.disabled {
            background: var(--phone-call-menu-btn-disabled-bg);
            pointer-events: none;

            svg {
              path, rect {
                fill: var(--phone-call-menu-btn-disabled-icon);
              }
            }
          }
        }

        .text {
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;
          letter-spacing: -0.01em;

          color: var(--font-panel);
        }
      }
    }

    .phone-down-btn {
      margin-top: 114px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        border-radius: 50%;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }
</style>
