export const Connection = {
    state: {

    },
    actions: {

    },
    mutations: {},
    getters: {}
}
