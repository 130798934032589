export default {
    recordID: '',
    backTitle: '',
    company: '',
    emailAddresses: [
        {
            label: 'Email',
            email: ''
        }
    ],
    displayName: '',
    familyName: '',
    givenName: '',
    middleName: '',
    jobTitle: '',
    phoneNumbers: [
        {
            label: '',
            number: ''
        }
    ],
    hasThumbnail: false,
    thumbnailPath: '',
    postalAddresses: [],
    prefix: '',
    suffix: '',
    department: '',
    imAddresses: [],
    note: '',
}
