import { render, staticRenderFns } from "./Calls.vue?vue&type=template&id=284f96d7&"
import script from "./Calls.vue?vue&type=script&lang=js&"
export * from "./Calls.vue?vue&type=script&lang=js&"
import style0 from "./Calls.vue?vue&type=style&index=0&id=284f96d7&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports